<template>
	<div class="d-flex justify-center flex-wrap">
		<SubscriptionPricingItem v-for="pricingItem in pricingList" :key="`pricing${pricingItem.code}`" :period="period" :pricingItem="pricingItem" />
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'SubscriptionPricingList',
	props: {
		period: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			pricingList: [
				{
					typeID: 1,
					code: 'free',
					title: this.$t('subscriptions.free'),
					priceMonthly: '0',
					priceYearly: '0',
					item1: `0 ${this.$t('offers.offers')}`,
					item2: `0 ${this.$t('events.events')}`,
					item3: `2 ${this.$t('companies.admins')}`,
					item4: '',
					description: this.$t('subscriptions.freeDescription'),
					recommended: false,
					purchasable: false
				},
				{
					typeID: 2,
					code: 'starter',
					title: this.$t('subscriptions.starter'),
					priceMonthly: '30',
					priceYearly: '300',
					item1: `2 ${this.$t('offers.offers')}`,
					item2: `1 ${this.$t('events.events')}`,
					item3: `2 ${this.$t('companies.admins')}`,
					item4: '',
					description: this.$t('subscriptions.starterDescription'),
					recommended: false,
					purchasable: true
				},
				{
					typeID: 3,
					code: 'medium',
					title: this.$t('subscriptions.medium'),
					priceMonthly: '100',
					priceYearly: '1000',
					item1: `10 ${this.$t('offers.offers')}`,
					item2: `5 ${this.$t('events.events')}`,
					item3: `3 ${this.$t('companies.admins')}`,
					item4: '',
					description: this.$t('subscriptions.mediumDescription'),
					recommended: true,
					purchasable: true
				},
				{
					typeID: 4,
					code: 'premium',
					title: this.$t('subscriptions.premium'),
					priceMonthly: '200',
					priceYearly: '2000',
					item1: `40 ${this.$t('offers.offers')}`,
					item2: `15 ${this.$t('events.events')}`,
					item3: `5 ${this.$t('companies.admins')}`,
					item4: this.$t('subscriptions.accessToTalentPool'),
					description: this.$t('subscriptions.premiumDescription'),
					recommended: false,
					purchasable: true
				},
				{
					typeID: 5,
					code: 'sponsor',
					title: this.$t('subscriptions.sponsor'),
					priceMonthly: '300',
					priceYearly: '3000',
					item1: this.$t('subscriptions.unlimitedOffersAndEvents'),
					item2: `40 ${this.$t('companies.admins')}`,
					item3: this.$t('subscriptions.accessToTalentPool'),
					item4: this.$t('subscriptions.becomeOurSponsor'),
					description: this.$t('subscriptions.sponsorDescription'),
					recommended: false,
					purchasable: true
				}
			]
		}
	},
	components: {
		SubscriptionPricingItem: () => import('@/components/subscriptions/SubscriptionPricingItem.vue')
	},
	created() {
		if (this.$route.name != 'Landing' && this.authenticated) {
			this.loading = true
			this.refreshAccess().then(() => (this.loading = false))
		}
	},
	computed: {
		...mapGetters({
			authenticated: 'auth/authenticated'
		})
	},
	methods: {
		...mapActions('auth', ['refreshAccess'])
	}
}
</script>
